import { parseResponse, parseErrorResponse, beforeRequest } from './_mixins';
import axios from 'axios';

axios.defaults.baseURL = 'https://hamiltonbright.brenzie.nl/api/';

export default class Api {
	constructor() {
		this.api = axios.create();
		this.api.interceptors.request.use(beforeRequest);
		this.api.interceptors.response.use(parseResponse, parseErrorResponse);
	}

	get(url) {
		return new Promise((resolve, reject) => {
			this.api
				.get(url)
				.then((response) => {
					if (response.data.status === 'success') {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch((error) => {
					reject(error.data);
				});
		});
	}

	post(url, data) {
		return new Promise((resolve, reject) => {
			this.api
				.post(url, data)
				.then((response) => {
					if (response.data.status === 'success') {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch((error) => {
					reject(error.data);
				});
		});
	}

	patch(url, data) {
		return new Promise((resolve, reject) => {
			this.api
				.patch(url, data)
				.then((response) => {
					if (response.data.status === 'success') {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch((error) => {
					reject(error.data);
				});
		});
	}

	delete(url) {
		return new Promise((resolve, reject) => {
			this.api
				.delete(url)
				.then((response) => {
					if (response.data.status === 'success') {
						resolve(response.data);
					} else {
						reject(response.data);
					}
				})
				.catch((error) => {
					reject(error.data);
				});
		});
	}
}
