export default {
  message: {
    work_at: 'Werken bij {appClient}',
    options: {
      yes: 'Ja',
      no: 'Nee'
    },
    greeting: {
      interested: 'Hallo, geïnteresseerde!',
      career: 'Als je geïnteresseerd bent in een carrière bij {appClient} dan verwijzen we je graag door naar onze pagina met vacatures. Zit er iets voor jou tussen? Dan horen we graag van je!',
      farewell: 'Misschien tot ziens,<br>Het {appClient} team',
      to_applications: 'Naar vacature pagina',
      applicant: 'Hallo, <span class="is_blue">{name}</span>',
      first_steps: `Hier de eerste stappen van jouw carrière bij {appClient}.
        We hebben nog even wat gegevens van je nodig om het
        alles helemaal compleet te maken. Zodra alles goed ingevuld en
        door ons bekeken is krijg je een link waarin je het contract kunt
        ondertekenen!`,
      personal: 'persoonlijke gegevens in',
      financial: 'financiële gegevens in'
    },
    steps: {
      personal: 'Persoonlijke gegevens',
      financial: 'Financiële gegevens',
      license: 'Rijbewijs en -ervaring'
    },
    instructions: {
      fill: 'Vul jouw <span class="is_blue">{pagedata}</span>',
      all_required: 'Om het formulier te versturen moeten alle gegevens ingevuld zijn!'
    },
    fields: {
      personal: {
        initials: 'Voorletters',
        first_name: 'Voornaam',
        inserts: 'Tussenvoegsel(s)',
        last_name: 'Achternaam',
        gender: 'Geslacht',
        male: 'Man',
        female: 'Vrouw',
        marital_status: 'Burgerlijke staat',
        maried: 'Gehuwd',
        not_maried: 'Ongehuwd',
        birth_date: 'Geboortedatum',
        birth_place: 'Geboorteplaats',
        address: 'Adres',
        house_number: 'Huisnummer',
        addition: 'Toevoeging',
        zip_code: 'Postcode',
        city: 'Woonplaats',
        email: 'E-mail adres',
        phone: 'Telefoonnummer',
        nationality: 'Nationaliteit',
        medical: 'Heb je medische beperkingen?',
        size: 'Wat is je maat voor een overhemd?'
      },
      financial: {
        iban: 'IBAN',
        name: 'Naam rekeninghouder',
        bank: 'Bank naam',
        bsn: 'BSN (sofinummer)',
        type: 'Soort ID-bewijs',
        national: 'Nationaal paspoort',
        nl: 'NL ID-kaart',
        eu: 'EU ID-kaart',
        alien: 'Vreemdelingen document',
        document_number: 'Paspoort/ ID-kaart nummer',
        expiration_date: 'Legitimatie geldig tot en met',
        stipp: 'Bouw je al pensioen op met Stipp?',
        stipp_type: 'Wat voor pensioen?',
        basic: 'Basispensioen',
        plus: 'Pluspensioen',
        loonheffingskorting: 'Loonheffingskorting toepassen',
        welfare: 'Ontvang je een uitkering?',
        welfare_type: 'Welke uitkering?',
        welfare_type_placeholder: 'Uitkering',
        other_job: 'Heb je nog een andere baan?',
        ov: 'Heb je een OV-jaarkaart?',
        none: 'Geen',
        week: 'Week',
        weekend: 'Weekend'
      },
      license: {
        owns: 'Heb je een rijbewijs?',
        name: 'Naam + voorletters',
        type: 'Type rijbewijs',
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
        limitations: 'Heb je een beperking op je rijbewijs?',
        limitations_description: 'Welke beperking?',
        limitations_description_placeholder: 'Beperking',
        created_at: 'Afgiftedatum rijbewijs',
        location: 'Afgifteplaats',
        number: 'Rijbewijsnummer',
        expiration: 'Geldig tot en met',
        revoked: 'Heb je ontzegging van je rijbevoegdheid?',
        revoked_reason: 'Waar en waarom?',
        fines: 'Heb je openstaande boetes?',
        experience_questions: 'Vragen over je <span class="is_blue">rij-ervaring</span>',
        years_experience: 'Aantal jaren rij-ervaring',
        serious_accident: 'Ben je de laatste 5 jaar betrokken geweest bij verkeersongevallen met dodelijke afloop of gewonden?',
        criminal_record: 'Ben je in dat verband veroordeeld?',
        own_car: 'Heb je een eigen auto?',
        insured: 'Is je auto verzekerd?',
        insured_passengers: 'Heb je een ongevallen inzittenden verzekering?',
        damage: 'Heb je de laatste 5 jaar schade gereden?'
      }
    },
    complete: {
      success: 'Gegevens ingevuld en opgestuurd',
      details: '{appClient} gaat nu je ingevulde gegevens controleren. Zodra deze goed bevonden zijn zul je een e-mail ontvangen met een link om je contract te ondertekenen.'
    },
    signed: {
      success: 'Het contract is ondertekend en opgestuurd',
      details: '{appClient} gaat nu het contract controleren. Zodra deze goed bevonden is zul je een e-mail ontvangen met inlog gegevens voor de app.'
    },
    controls: {
      next: 'Volgende',
      send: 'Versturen',
      back: 'Terug'
    },
    signing: {
      signed: 'Documenten zijn ondertekend.',
      signature: 'Handtekening',
      undo: 'Ongedaan maken',
      clear: 'Wissen',
      declare: 'Ik teken voor zowel mijn contract als het opgaveformulier voor de loonheffingen en de gedragscode.',
      sign: 'Ondertekenen'
    }
  }
}