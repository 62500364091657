<template>
  <div id="steps_container">
      <div class="steps">
          <div class="step-item is-info" :class="stepOne">
              <div class="step-marker">
                  <!-- <span class="icon">
                      <i class="fa fa-check"></i>
                  </span> -->
              </div>
              <div class="step-details is-size-6 is-info">
                  <p class="is_blue" :class="{ 'italic': stepCount == 2 || stepCount == 3}">{{ $t('message.steps.personal') }}</p>
              </div>
          </div>
          <div class="step-item is-info" :class="stepTwo">
              <div class="step-marker"></div>
              <div class="step-details is-size-6" :class="{ 'is_blue': stepCount == 2, 'italic is_blue': stepCount == 3}">
                  <p>{{ $t('message.steps.financial') }}</p>
              </div>
          </div>
          <div class="step-item is-info" :class="stepThree">
              <div class="step-marker">
                  <span v-if="complete" class="icon">
                      <i class="fa fa-check"></i>
                  </span>
              </div>
              <div class="step-details is-size-6" :class="{ 'is_blue': stepCount == 3, 'italic is_blue': stepCount > 3}">
                  <p>{{ $t('message.steps.license') }}</p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    props: ['stepCount', 'complete'],
    computed: {
      stepOne: function () {
        return {
            'is-active': this.stepCount === 1,
            'is-completed': this.stepCount !== 1
          }
        },
      stepTwo: function () {
        return {
            'is-active': this.stepCount === 2,
            'is-completed': this.stepCount > 2
          }
        },
      stepThree: function () {
        return {
            'is-active': this.stepCount === 3,
            'is-completed': this.stepCount > 3
          }
        }
    },
  }
</script>

<style lang="scss" scoped>
  #steps_container {
      justify-content: center;
      text-align: -webkit-center;
      .steps {
          margin: 50px 0px;
          max-width: 629px;
          .color_primary {
              color: #2495df;
          }
      }
  }
  .italic {
    font-style: italic;
  }
</style>