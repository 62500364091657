<template>
  <div>
    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.license.owns') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="licence.drivers_licence"
                :class="{ 'is-red': required('drivers_licence') }"
                :value="'1'"
                type="radio"
                name="drivers_licence"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence') }"
                >{{ $t('message.options.yes') }}</i
              >
            </label>
            <label class="radio">
              <input
                v-model="licence.drivers_licence"
                :class="{ 'is-red': required('drivers_licence') }"
                :value="'0'"
                type="radio"
                name="drivers_licence"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence') }"
                >{{ $t('message.options.no') }}</i
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <template v-if="licence.drivers_licence == '1'">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.name') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="licence.drivers_licence_name"
                class="input"
                :class="{ 'is-danger': required('drivers_licence_name') }"
                type="text"
                :placeholder="`${$t('message.fields.license.name')}...`"
              />
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >{{ $t('message.fields.license.type') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr_responsive">
              <input
                v-model="licence.drivers_licence_type"
                value="A"
                type="radio"
                name="drivers_licence_type"
                :class="{ 'is-danger': required('drivers_licence_type') }"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence_type') }">{{ $t('message.fields.license.a') }}</i>
            </label>
            <label class="radio mr_responsive">
              <input
                v-model="licence.drivers_licence_type"
                value="B"
                type="radio"
                name="drivers_licence_type"
                :class="{ 'is-danger': required('drivers_licence_type') }"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence_type') }">{{ $t('message.fields.license.b') }}</i>
            </label>
            <label class="radio mr_responsive">
              <input
                v-model="licence.drivers_licence_type"
                value="C"
                type="radio"
                name="drivers_licence_type"
                :class="{ 'is-danger': required('drivers_licence_type') }"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence_type') }">{{ $t('message.fields.license.c') }}</i>
            </label>
            <label
              class="radio mr_responsive"
              :class="{ 'is-danger': required('drivers_licence_type') }"
            >
              <input
                v-model="licence.drivers_licence_type"
                value="D"
                type="radio"
                name="drivers_licence_type"
                :class="{ 'is-danger': required('drivers_licence_type') }"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence_type') }">{{ $t('message.fields.license.d') }}</i>
            </label>
            <label class="radio">
              <input
                v-model="licence.drivers_licence_type"
                value="E"
                type="radio"
                name="drivers_licence_type"
                :class="{ 'is-danger': required('drivers_licence_type') }"
              />
              <i class="m-2" :class="{ is_red: required('drivers_licence_type') }">{{ $t('message.fields.license.e') }}</i>
            </label>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.limitations') }}
              <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.drivers_licence_limitations"
                  :class="{ 'is-red': required('drivers_licence_limitations') }"
                  :value="'1'"
                  type="radio"
                  name="drivers_licence_limitations"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_limitations') }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.drivers_licence_limitations"
                  :class="{ 'is-red': required('drivers_licence_limitations') }"
                  :value="'0'"
                  type="radio"
                  name="drivers_licence_limitations"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_limitations') }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <label class="label"
            ><span :class="{ is_grey: licence.drivers_licence_limitations != '1' }"
              >{{ $t('message.fields.license.limitations_description') }} </span
            ><span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="licence.drivers_licence_limitations_description"
              class="input"
              :class="{ 'is-danger': required('drivers_licence_limitations_description') }"
              type="text"
              :placeholder="`${$t('message.fields.license.limitations_description_placeholder')}...`"
              :disabled="licence.drivers_licence_limitations != '1'"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.created_at') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <b-field>
                <b-datepicker
                  v-model="licence.drivers_licence_created_at"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  :class="{ 'is-danger': required('drivers_licence_created_at') }"
                  placeholder="dd-mm-jj"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.location') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="licence.drivers_licence_location"
                class="input"
                :class="{ 'is-danger': required('drivers_licence_location') }"
                type="text"
                :placeholder="`${$t('message.fields.license.location')}...`"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              ><span>{{ $t('message.fields.license.number') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="licence.drivers_licence_number"
                class="input"
                :class="{ 'is-danger': required('drivers_licence_number') }"
                type="text"
                :placeholder="`${$t('message.fields.license.number')}...`"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.expiration') }} <span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <b-field>
                <b-datepicker
                  v-model="licence.drivers_licence_expiration_date"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  :class="{ 'is-danger': required('drivers_licence_expiration_date') }"
                  placeholder="dd-mm-jj"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.revoked') }}
              <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.drivers_licence_revoked"
                  :class="{ 'is-red': required('drivers_licence_revoked') }"
                  :value="'1'"
                  type="radio"
                  name="drivers_licence_revoked"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_revoked') }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.drivers_licence_revoked"
                  :class="{ 'is-red': required('drivers_licence_revoked') }"
                  :value="'0'"
                  type="radio"
                  name="drivers_licence_revoked"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_revoked') }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              ><span :class="{ is_grey: licence.drivers_licence_revoked != '1' }"
                >{{ $t('message.fields.license.revoked_reason') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="licence.drivers_licence_revoked_reason"
                class="input"
                :class="{ 'is-danger': required('drivers_licence_revoked_reason') }"
                type="text"
                :placeholder="`${$t('message.fields.license.revoked_reason')}...`"
                :disabled="licence.drivers_licence_revoked != '1'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.fines') }} <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.drivers_licence_outstanding_fines"
                  :class="{ 'is-red': required('drivers_licence_outstanding_fines') }"
                  :value="'1'"
                  type="radio"
                  name="drivers_licence_outstanding_fines"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_outstanding_fines') }">{{ $t('message.options.yes') }}</i>
              </label>
              <label class="radio">
                <input
                  v-model="licence.drivers_licence_outstanding_fines"
                  :class="{ 'is-red': required('drivers_licence_outstanding_fines') }"
                  :value="'0'"
                  type="radio"
                  name="drivers_licence_outstanding_fines"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_outstanding_fines') }">{{ $t('message.options.no') }}</i>
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field"></div>
        </div>
      </div>
      <div class="mt-6">
        <h2 class="title is-4">
          <span v-html="$t('message.fields.license.experience_questions')"/>
        </h2>
      </div>
      <div class="columns mt-5">
        <div class="column">
          <div class="field">
            <label class="label"
              ><span>{{ $t('message.fields.license.years_experience') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control pr-2">
              <input
                v-model="licence.drivers_licence_years_of_experience"
                class="input"
                :class="{ 'is-danger': required('drivers_licence_years_of_experience') }"
                type="text"
                :placeholder="`${$t('message.fields.license.years_experience')}...`"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field"></div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >
              {{ $t('message.fields.license.serious_accident') }}
              <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.drivers_licence_serious_accident"
                  :class="{ 'is-red': required('drivers_licence_serious_accident') }"
                  :value="'1'"
                  type="radio"
                  name="drivers_licence_serious_accident"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_serious_accident') }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.drivers_licence_serious_accident"
                  :class="{ 'is-red': required('drivers_licence_serious_accident') }"
                  :value="'0'"
                  type="radio"
                  name="drivers_licence_serious_accident"
                />
                <i class="m-2" :class="{ is_red: required('drivers_licence_serious_accident') }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              ><span :class="{ is_grey: licence.drivers_licence_serious_accident != '1' }"
                >{{ $t('message.fields.license.criminal_record') }}</span
              >
              <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.drivers_licence_criminal_record"
                  :class="{ 'is-red': required('drivers_licence_criminal_record') }"
                  :value="'1'"
                  type="radio"
                  name="drivers_licence_criminal_record"
                  :disabled="licence.drivers_licence_serious_accident != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('drivers_licence_criminal_record'),
                    is_grey: licence.drivers_licence_serious_accident != '1',
                  }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.drivers_licence_criminal_record"
                  :class="{ 'is-red': required('drivers_licence_criminal_record') }"
                  :value="'0'"
                  type="radio"
                  name="drivers_licence_criminal_record"
                  :disabled="licence.drivers_licence_serious_accident != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('drivers_licence_criminal_record'),
                    is_grey: licence.drivers_licence_serious_accident != '1',
                  }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label"
              >{{ $t('message.fields.license.own_car') }} <span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.car_owner"
                  :class="{ 'is-red': required('car_owner') }"
                  :value="'1'"
                  type="radio"
                  name="car_owner"
                />
                <i class="m-2" :class="{ is_red: required('car_owner') }">{{ $t('message.options.yes') }}</i>
              </label>
              <label class="radio">
                <input
                  v-model="licence.car_owner"
                  :class="{ 'is-red': required('car_owner') }"
                  :value="'0'"
                  type="radio"
                  name="car_owner"
                />
                <i class="m-2" :class="{ is_red: required('car_owner') }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              ><span :class="{ is_grey: licence.car_owner != '1' }"
                >{{ $t('message.fields.license.insured') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.car_insured"
                  :class="{ 'is-red': required('car_insured') }"
                  :value="'1'"
                  type="radio"
                  name="car_insured"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_insured'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.car_insured"
                  :class="{ 'is-red': required('car_insured') }"
                  :value="'0'"
                  type="radio"
                  name="car_insured"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_insured'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field"></div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              ><span :class="{ is_grey: licence.car_owner != '1' }"
                >{{ $t('message.fields.license.insured_passengers') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.car_insured_passengers"
                  :class="{ 'is-red': required('car_insured_passengers') }"
                  :value="'1'"
                  type="radio"
                  name="car_insured_passengers"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_insured_passengers'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.car_insured_passengers"
                  :class="{ 'is-red': required('car_insured_passengers') }"
                  :value="'0'"
                  type="radio"
                  name="car_insured_passengers"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_insured_passengers'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field"></div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label"
              ><span :class="{ is_grey: licence.car_owner != '1' }"
                >{{ $t('message.fields.license.damage') }} </span
              ><span class="is_blue">*</span></label
            >
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="licence.car_damaged"
                  :class="{ 'is-red': required('car_damaged') }"
                  :value="'1'"
                  type="radio"
                  name="car_damaged"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_damaged'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.yes') }}</i
                >
              </label>
              <label class="radio">
                <input
                  v-model="licence.car_damaged"
                  :class="{ 'is-red': required('car_damaged') }"
                  :value="'0'"
                  type="radio"
                  name="car_damaged"
                  :disabled="licence.car_owner != '1'"
                />
                <i
                  class="m-2"
                  :class="{
                    is_red: required('car_damaged'),
                    is_grey: licence.car_owner != '1',
                  }"
                  >{{ $t('message.options.no') }}</i
                >
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["licence", "checked"],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined, // Browser locale
    };
  },
  methods: {
    required: function() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.whitespace {
  padding-top: 262px;
}
.whitespace_small {
  padding-top: 40px;
}
.mr_responsive {
  margin-right: 5%;
}
</style>
