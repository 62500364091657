<template>
  <div>
    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.financial.iban') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="financial.iban"
              class="input"
              :class="{ 'is-danger': required('iban') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.iban')}...`"
              tabindex="1"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.name') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="financial.account_holder"
              class="input"
              :class="{ 'is-danger': required('account_holder') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.name')}...`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.financial.bank') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="financial.account_bank"
              class="input"
              :class="{ 'is-danger': required('account_bank') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.bank')}...`"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.bsn') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="financial.bsn"
              class="input"
              :class="{ 'is-danger': required('bsn') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.bsn')}...`"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field mb-5">
      <label class="label"
        >{{ $t('message.fields.financial.type') }} <span class="is_blue">*</span></label
      >
      <div class="control">
        <label class="radio mr_responsive">
          <input
            v-model="financial.document_type"
            value="Nationaal Paspoort"
            type="radio"
            name="document_type"
            :class="{ 'is-danger': required('document_type') }"
          />
          <i class="m-2" :class="{ is_red: required('document_type') }"
            >{{ $t('message.fields.financial.national') }}</i
          >
        </label>
        <label class="radio mr_responsive">
          <input
            v-model="financial.document_type"
            value="NL id-kaart"
            type="radio"
            name="document_type"
            :class="{ 'is-danger': required('document_type') }"
          />
          <i class="m-2" :class="{ is_red: required('document_type') }"
            >{{ $t('message.fields.financial.nl') }}</i
          >
        </label>
        <label class="radio mr_responsive">
          <input
            v-model="financial.document_type"
            value="EU id-kaart"
            type="radio"
            name="document_type"
            :class="{ 'is-danger': required('document_type') }"
          />
          <i class="m-2" :class="{ is_red: required('document_type') }"
            >{{ $t('message.fields.financial.eu') }}</i
          >
        </label>
        <label
          class="radio mr_responsive"
          :class="{ 'is-danger': required('document_type') }"
        >
          <input
            v-model="financial.document_type"
            value="Vreemdelingen document"
            type="radio"
            name="document_type"
            :class="{ 'is-danger': required('document_type') }"
          />
          <i class="m-2" :class="{ is_red: required('document_type') }"
            >{{ $t('message.fields.financial.alien') }}</i
          >
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.document_number') }} <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="financial.document_number"
              class="input"
              :class="{ 'is-danger': required('document_number') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.document_number')}...`"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.expiration_date') }} <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <b-field>
              <b-datepicker
                v-model="financial.document_expiration_date"
                :show-week-number="showWeekNumber"
                :locale="locale"
                :class="{ 'is-danger': required('document_expiration_date') }"
                placeholder="dd-mm-jj"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.stipp') }}
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="financial.retirement_plan"
                :class="{ 'is-red': required('retirement_plan') }"
                :value="'1'"
                type="radio"
                name="retirement_plan"
              />
              <i class="m-2" :class="{ is_red: required('retirement_plan') }"
                >{{ $t('message.options.yes') }}</i
              >
            </label>
            <label class="radio">
              <input
                v-model="financial.retirement_plan"
                :class="{ 'is-red': required('retirement_plan') }"
                :value="'0'"
                type="radio"
                name="retirement_plan"
              />
              <i class="m-2" :class="{ is_red: required('retirement_plan') }"
                >{{ $t('message.options.no') }}</i
              >
            </label>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            ><span :class="{ is_grey: financial.retirement_plan != '1' }"
              >{{ $t('message.fields.financial.stipp_type') }}</span
            >
            <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="financial.retirement_plan_type"
                :class="{ 'is-red': required('retirement_plan_type') }"
                :value="'Basispensioen'"
                type="radio"
                name="retirement_plan_type"
                :disabled="financial.retirement_plan != '1'"
              />
              <i
                class="m-2"
                :class="{
                  is_red: required('retirement_plan_type'),
                  is_grey: financial.retirement_plan != '1',
                }"
                >{{ $t('message.fields.financial.basic') }}</i
              >
            </label>
            <label class="radio mr-5">
              <input
                v-model="financial.retirement_plan_type"
                :class="{ 'is-red': required('retirement_plan_type') }"
                :value="'Pluspensioen'"
                type="radio"
                name="retirement_plan_type"
                :disabled="financial.retirement_plan != '1'"
              />
              <i
                class="m-2"
                :class="{
                  is_red: required('retirement_plan_type'),
                  is_grey: financial.retirement_plan != '1',
                }"
                >{{ $t('message.fields.financial.plus') }}</i
              >
            </label>
          </div>
        </div>
      </div>
    </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">{{ $t('message.fields.financial.loonheffingskorting') }}</label>
            <div class="control">
              <label class="radio mr-5">
                <input
                  v-model="financial.loonheffingskorting"
                  type="radio"
                  :value="1"
                  :class="{ 'is-danger': required('loonheffingskorting') }"
                  name="loonheffingskorting"
                  checked
                />
                <i class="m-2" :class="{ is_red: required('loonheffingskorting') }">{{ $t('message.options.yes') }}</i>
              </label>
              <label class="radio">
                <input
                  v-model="financial.loonheffingskorting"
                  type="radio"
                  :value="0"
                  :class="{ 'is-danger': required('loonheffingskorting') }"
                  name="loonheffingskorting"
                />
                <i class="m-2" :class="{ is_red: required('loonheffingskorting') }">{{ $t('message.options.no') }}</i>
              </label>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.welfare') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="financial.welfare"
                :class="{ 'is-red': required('benefits') }"
                :value="'1'"
                type="radio"
                name="benefits"
              />
              <i class="m-2" :class="{ is_red: required('benefits') }">{{ $t('message.options.yes') }}</i>
            </label>
            <label class="radio">
              <input
                v-model="financial.welfare"
                :class="{ 'is-red': required('benefits') }"
                :value="'0'"
                type="radio"
                name="benefits"
              />
              <i class="m-2" :class="{ is_red: required('benefits') }">{{ $t('message.options.no') }}</i>
            </label>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            ><span :class="{ is_grey: financial.welfare != '1' }"
              >{{ $t('message.fields.financial.welfare_type') }} </span
            ><span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="financial.welfare_type"
              class="input"
              :class="{ 'is-danger': required('welfare') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.welfare_type_placeholder')}...`"
              :disabled="financial.welfare != '1'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.other_job') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="financial.other_job"
                :class="{ 'is-red': required('other_job') }"
                :value="'1'"
                type="radio"
                name="other_job"
              />
              <i class="m-2" :class="{ is_red: required('other_job') }">{{ $t('message.options.yes') }}</i>
            </label>
            <label class="radio">
              <input
                v-model="financial.other_job"
                :class="{ 'is-red': required('other_job') }"
                :value="'0'"
                type="radio"
                name="other_job"
              />
              <i class="m-2" :class="{ is_red: required('other_job') }">{{ $t('message.options.no') }}</i>
            </label>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field"></div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.ov') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="financial.ov_type"
                :class="{ 'is-red': required('ov_type') }"
                value="Geen"
                type="radio"
                name="ov_type"
              />
              <i class="m-2" :class="{ is_red: required('ov_type') }">{{ $t('message.fields.financial.none') }}</i>
            </label>
            <label class="radio mr-5">
              <input
                v-model="financial.ov_type"
                :class="{ 'is-red': required('ov_type') }"
                value="Week-ov"
                type="radio"
                name="ov_type"
              />
              <i class="m-2" :class="{ is_red: required('ov_type') }">{{ $t('message.fields.financial.week') }}</i>
            </label>
            <label class="radio">
              <input
                v-model="financial.ov_type"
                :class="{ 'is-red': required('ov_type') }"
                value="Weekend-ov"
                type="radio"
                name="ov_type"
              />
              <i class="m-2" :class="{ is_red: required('ov_type') }"
                >{{ $t('message.fields.financial.weekend') }}</i
              >
            </label>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["financial", "checked"],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined, // Browser locale
    };
  },
  methods: {
    required: function() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mr_responsive {
  margin-right: 5%;
}
</style>
