export default {
  message: {
    work_at: 'Working at {appClient}',
    options: {
      yes: 'Yes',
      no: 'No'
    },
    greeting: {
      interested: 'Hello, interested!',
      career: 'If you are interested in a career at {appClient}, we would like to refer you to our vacancies page. Is there something for you? Then we would love to hear from you! ',
      farewell: 'Maybe goodbye, <br> The {appClient} team',
      to_applications: 'To vacancy page',
      applicant: 'Hello, <span class = "is_blue"> {name} </span>',
      first_steps: `Here are the first steps of your career at {appClient}.
        We need some more information from you to finalize your application. Once everything is filled in and
        has been reviewed by us, you will receive a link where you can sign your contract!`,
      personal: 'personal details',
      financial: 'financial details'
    },
    steps: {
      personal: 'Personal data',
      financial: 'Financial data',
      license: 'Drivers license and experience'
    },
    instructions: {
      fill: 'Enter your <span class = "is_blue"> {pagedata} </span>',
      all_required: 'All data must be filled in to send the form!'
    },
    fields: {
      personal: {
        initials: 'Initials',
        first_name: 'First name',
        inserts: 'Prefix',
        last_name: 'Last name',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        marital_status: 'Marital status',
        maried: 'Married',
        not_maried: 'Unmarried',
        birth_date: 'Birthdate',
        birth_place: 'Birthplace',
        address: 'Address',
        house_number: 'House number',
        addition: 'Addition',
        zip_code: 'Postcode',
        city: 'City',
        email: 'E-mail address',
        phone: 'Phone number',
        nationality: 'Nationality',
        medical: 'Do you have any medical limitations?',
        size: "What is your shirt size?"
      },
      financial: {
        iban: 'IBAN',
        name: 'Name account holder',
        bank: 'Bank name',
        bsn: 'BSN (social security number)',
        type: 'Type of ID card',
        national: 'National passport',
        nl: 'NL ID card',
        eu: 'EU ID card',
        alien: 'Residence permit document',
        document_number: 'Passport / ID card number',
        expiration_date: 'Identification valid until',
        stipp: 'Do you have a pension with Stipp?',
        stipp_type: 'What kind of pension?',
        basic: 'Basic pension',
        plus: 'Plus pension',
        loonheffingskorting: 'Apply payroll tax credit',
        welfare: 'Do you receive social benefits?',
        welfare_type: 'Which social benefit(s)?',
        welfare_type_placeholder: 'Benefit(s)',
        other_job: 'Do you have another job?',
        ov: 'Do you have a public transport card?',
        none: 'None',
        week: 'Week',
        weekend: 'Weekend'
      },
      license: {
        owns: 'Do you have a drivers license?',
        name: 'Name + initials',
        type: 'Drivers license type',
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
        limitations: 'Do you have any restrictions on your drivers license?',
        limitations_description: 'What restrictions?',
        limitations_description_placeholder: 'Restrictions',
        created_at: 'Drivers license issue date',
        location: 'Drop off location',
        number: 'Drivers license number',
        expiration: 'Valid until',
        revoked: 'Have you been disqualified from driving?',
        revoked_reason: 'Where and why?',
        fines: 'Do you have any outstanding fines?',
        experience_questions: 'Questions about your <span class = "is_blue"> driving experience </span>',
        years_experience: 'Number of years of driving experience',
        serious_accident: 'Have you been involved in fatal or injured traffic accidents in the last 5 years?',
        criminal_record: 'Have you been convicted in that regard?',
        own_car: 'Do you have your own car?',
        insured: 'Is your car insured?',
        insured_passengers: 'Do you have passenger accident insurance?',
        damage: 'Have you caused damage in traffic, while driving, in the last 5 years?'
      }
    },
    complete: {
      success: 'Data entered and sent',
      details: '{appClient} will now check your entered data. When your date has been reviewed you will receive an email with a link to sign your contract.'
    },
    signed: {
      success: 'The contract has been signed and sent',
      details: '{appClient} will now check the contract. As soon as it has been approved, you will receive an email with login details for the app.'
    },
    controls: {
      next: 'Next',
      send: 'Send',
      back: 'Back'
    },
    signing: {
      signed: 'Documents have been signed.',
      signature: 'Signature',
      undo: 'Undo',
      clear: 'Clear',
      declare: 'I hereby declare I am signing my contract, the wage tax deduction form and the code of conduct.',
      sign: 'Sign'
    }
  }
}