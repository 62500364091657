<template>
  <div v-if="approved !== 0" class="page-container">
    <!-- NAVBAR -->
    <nav class="navbar">
      <section class="section">
        <h1 class="mr-3">{{ $t('message.work_at', { appClient: $appClient }) }}</h1>
      </section>
      <div class="language-selector" v-if="approved != 3 && !complete">
        <a 
          v-for="locale in locales" 
          :key="locale.language"
          href="#"
          @click.prevent="switchLocale(locale)"
        >
          <i
            class="flag-icon flag-icon-squared" 
            :class="`flag-icon-${locale.flag}`"
          />
        </a>
      </div>
      <div class="layer" />
    </nav>

    <!-- NO HASH AND CHECKSUM -->
    <div v-if="noApplicantExists" class="no-applicant-exists">
      <main>
        <section v-if="!sign" id="meta" class="section">
          <h2 class="title is-2">
            {{ $t('message.greeting.interested') }}
          </h2>
          <p>
            {{ $t('message.greeting.career', { appClient: $appClient } ) }}
            <br><br>
            <i v-html="$t('message.greeting.farewell', { appClient: $appClient })"/>
          </p>
          <a :href="`${this.$appUrl}`">
            <button class="button is-info job-openings">
              <span>{{ $t('message.greeting.to_applications') }}</span>
            </button>
          </a>
        </section>
      </main>
    </div>

    <!-- HASH AND CHECKSUM EXIST -->
    <div v-else-if="approved == 1 || approved == 2" class="container is-max-widescreen" :class="{ sign_modal :approved == 3}">
      <main>
        <section v-if="!sign" id="meta" class="section">
          <h2 
            class="title is-2" 
            v-html="$t('message.greeting.applicant', { name: `${data.personal.first_name} ${data.personal.last_name}` })"
          />
          <p>
            <i>{{ $t('message.greeting.first_steps', { appClient: $appClient }) }}</i>
          </p>
          <stepbar :stepCount="stepCount" :complete="complete" />
        </section>
        <section class="section pt-0">
          <div>
            <h2 v-if="stepCount == 1 || stepCount == 2" class="title is-4">
              <span v-html="$t('message.instructions.fill', { pagedata })"/>
            </h2>
            <h2 v-else-if="stepCount == 3 && !complete" class="title is-4">
              Vragen over je <span class="is_blue">rijbewijs</span>
            </h2>
            <p v-if="!complete">
              <i>{{ $t('message.instructions.all_required') }}</i>
            </p>
          </div>

          <!-- PERSONAL INFO -->
          <personal
            v-if="stepCount == 1"
            :personal="data.personal"
            :checked="firstCheck"
            :validation="error_msg"
          />

          <!-- FINANCIAL INFO -->
          <financial
            v-if="stepCount == 2"
            :financial="data.financial"
            :checked="secondCheck"
          />

          <!-- DRIVERS LICENCE -->
          <driver-licence
            v-if="stepCount == 3 && !complete"
            :licence="data.licence"
            :checked="lastCheck"
          />

          <!-- INFO SENT -->
          <complete v-if="complete" :type="'info'" />
        </section>

        <!-- ERROR MESSAGE -->
        <div class="error_msg" v-if="error">
          <transition name="fade">
            <div class="notification is-danger p-5">
              <button class="delete" @click="error = ''" />
              {{ error }}
            </div>
          </transition>
        </div>
      </main>

      <!-- BUTTONS FOR INFO -->
      <section v-if="!complete" class="section">
        <div class="buttons">
          <button v-if="stepCount > 1" class="button is-dark" @click="prevStep">
            <span class="icon">
              <i class="fas fa-arrow-left is_white"></i>
            </span>
            <span class="is_white">{{ $t('message.controls.back') }}</span>
          </button>
          <div v-else></div>
          <button class="button is-info" @click="nextStep" :disabled="nextBtnDisabled">
            <span>{{ $t(`message.controls.${stepCount === 3 ? "send" : "next"}`) }}</span>
            <span class="icon">
              <i class="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </section>
    </div>

    <!-- SIGN CONTRACT -->
    <div v-else-if="approved == 3 && !signed">
      <main>
        <div>
          <sign-contract
            v-if="!loading"
            :data="data"
            :hash="hash"
            :checksum="checksum"
            :language="language"
            :setSigned="() => { signed = true }"
          />
        </div>
      </main>
    </div>

    <!-- CONTRACT SIGNED -->
    <div v-else-if="signed" class="container is-max-widescreen">
      <main>
        <section class="section pt-0">
          <complete v-if="signed" :type="'signed'" />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Personal from "../Pages/Personal.vue";
import Stepbar from "../components/stepbar.vue";
import Financial from "../Pages/Financial.vue";
import DriverLicence from "../Pages/DriverLicence.vue";
import Complete from "../Pages/Complete.vue";
import SignContract from "../Pages/SignContract.vue";

import Api from "../api/api";

import _ from "lodash";
import moment from "moment";

let api = new Api();

export default {
  components: {
    Personal,
    Stepbar,
    Financial,
    DriverLicence,
    Complete,
    SignContract,
  },
  data() {
    return {
      loading: true,
      hash: null,
      checksum: null,
      username: "Test",
      stepCount: 1,
      approved: null,
      data: {
        personal: {
          first_name: null,
          initials: null,
          last_name: null,
          last_name_prefix: null,
          sex: null,
          date_of_birth: null,
          place_of_birth: null,
          nationality: null,
          street_name: null,
          number: null,
          number_extention: null,
          zip_code: null,
          city: null,
          mobile: null,
          email: null,
          marital_status: null,
          medical_restriction: null,
          shirt_size: null,
        },
        financial: {
          iban: null,
          account_bank: null,
          account_holder: null,
          bsn: null,
          document_type: null,
          document_number: null,
          document_expiration_date: null,
          ov_type: null,
          retirement_plan: null,
          retirement_plan_type: null,
          welfare: null,
          welfare_type: null,
          other_job: null,
          loonheffingskorting: null,
        },
        licence: {
          drivers_licence: null,
          drivers_licence_name: null,
          drivers_licence_type: null,
          drivers_licence_limitations: null,
          drivers_licence_limitations_description: null,
          drivers_licence_number: null,
          drivers_licence_created_at: null,
          drivers_licence_expiration_date: null,
          drivers_licence_revoked: null,
          drivers_licence_revoked_reason: null,
          drivers_licence_outstanding_fines: null,
          drivers_licence_years_of_experience: null,
          drivers_licence_serious_accident: null,
          drivers_licence_criminal_record: null,
          drivers_licence_location: null,
          car_insured: null,
          car_insured_passengers: null,
          car_damaged: null,
          car_owner: null,
        },
      },
      noApplicantExists: false,
      sign: false,
      firstCheck: false,
      secondCheck: false,
      lastCheck: false,
      error: "",
      complete: false,
      signed: false,
      error_msg: "",
      language: 'nl',
      locales: [{
        i18n: 'nl',
        flag: 'nl',
        language: 'nl'
      }, {
        i18n: 'en',
        flag: 'gb',
        language: 'en'
      }]
    };
  },
  computed: {
    pagedata() {
      if (this.stepCount === 1) {
        return this.$t('message.greeting.personal')
      }
      return this.$t('message.greeting.financial')
    },

    nextBtnDisabled() {
      // Next button enabled for step 1
      if (this.stepCount == 1) {
        let newData = { ...this.data.personal };
        delete newData.last_name_prefix;
        delete newData.number_extention;
        if (Object.values(newData).some(x => (x === undefined || x === null || x === ''))) return true
        return false
      }

      // Next button enabled for step 2
      if (this.stepCount == 2) {
        let newData = { ...this.data.financial };
        if (newData.retirement_plan && newData.retirement_plan == "0") {
          delete newData.retirement_plan_type;
        }
        if (newData.welfare && newData.welfare == "0") {
          delete newData.welfare_type;
        }
        if (Object.values(newData).some(x => (x === undefined || x === null || x === ''))) return true
        return false
      }

      // Next button enabled for step 3
      if (this.stepCount == 3) {
        let newData = { ...this.data.licence };
        if (newData.drivers_licence && newData.drivers_licence == "0") {
          newData = { drivers_licence: "0" };
        }
        if (
          newData.drivers_licence_limitations &&
          newData.drivers_licence_limitations == "0"
        ) {
          delete newData.drivers_licence_limitations_description;
        }
        if (
          newData.drivers_licence_revoked &&
          newData.drivers_licence_revoked == "0"
        ) {
          delete newData.drivers_licence_revoked_reason;
        }
        if (
          newData.drivers_licence_serious_accident &&
          newData.drivers_licence_serious_accident == "0"
        ) {
          delete newData.drivers_licence_criminal_record;
        }
        if (newData.car_owner && newData.car_owner == "0") {
          delete newData.car_insured;
          delete newData.car_insured_passengers;
          delete newData.car_damaged;
        }
        if (Object.values(newData).some(x => (x === undefined || x === null || x === ''))) return true
        return false
      }

      return true
    }
  },

  mounted() {
    this.loading = true
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("hash")) this.hash = urlParams.get("hash");
    if (urlParams.has("checksum")) this.checksum = urlParams.get("checksum");
    if (urlParams.has("sign")) this.sign = true

    if (this.hash && this.checksum) {
      this.validateApplicant();
    } else {
      this.noApplicantExists = true;
    }
  },

  watch: {
    "data.personal.email"(value) {
      this.data.personal.email = value;
      this.validateEmail(value);
    },
  },

  methods: {
    nextStep() {
      let newData = null;
      if (this.stepCount === 1) {
        newData = { ...this.data.personal };
        delete newData.last_name_prefix;
        delete newData.number_extention;
        this.firstCheck = true;
      } else if (this.stepCount === 2) {
        newData = { ...this.data.financial };
        if (newData.retirement_plan && newData.retirement_plan == "0") {
          delete newData.retirement_plan_type;
        }
        if (newData.welfare && newData.welfare == "0") {
          delete newData.welfare_type;
        }
        this.secondCheck = true;
      } else {
        newData = { ...this.data.licence };
        if (newData.drivers_licence && newData.drivers_licence == "0") {
          newData = { drivers_licence: "0" };
        }
        if (
          newData.drivers_licence_limitations &&
          newData.drivers_licence_limitations == "0"
        ) {
          delete newData.drivers_licence_limitations_description;
        }
        if (
          newData.drivers_licence_revoked &&
          newData.drivers_licence_revoked == "0"
        ) {
          delete newData.drivers_licence_revoked_reason;
        }
        if (
          newData.drivers_licence_serious_accident &&
          newData.drivers_licence_serious_accident == "0"
        ) {
          delete newData.drivers_licence_criminal_record;
        }
        if (newData.car_owner && newData.car_owner == "0") {
          delete newData.car_insured;
          delete newData.car_insured_passengers;
          delete newData.car_damaged;
        }
        this.lastCheck = true;
      }

      if (!_.isEmpty(newData) && this.stepCount < 3) {
        this.stepCount++;
      } else if (this.isEmpty(newData) && this.stepCount === 3) {
        this.updateContractData();
      } else {
        setTimeout(() => {
          this.error = "";
        }, 5000);
        return (this.error = "Required information is missing");
      }
    },

    isEmpty(obj) {
      for (let [key, value] of Object.entries(obj)) {
        if (
          value &&
          (key == "date_of_birth" ||
            key == "document_expiration_date" ||
            key == "drivers_licence_created_at" ||
            key == "drivers_licence_expiration_date")
        ) {
          value = moment(value).format("YYYY-MM-DD");
        }
        if (key && (!value || _.isEmpty(value))) {
          return false;
        }
      }
      return true;
    },

    prevStep() {
      if (this.stepCount > 1) {
        this.stepCount--;
      }
    },

    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        this.error_msg = "";
      } else {
        this.error_msg = "Invalid Email street_name";
      }
    },

    validateApplicant() {
      this.loading = true
      api
        .get(`/applicant/${this.hash}/${this.checksum}/validate`)
        .then((response) => {
          this.data.personal.first_name = response.data.first_name;
          this.data.personal.last_name = response.data.last_name;
          this.data.personal.email = response.data.email;
          this.data.personal.mobile = response.data.mobile;
          this.language = response.data.language;
          this.$i18n.locale = this.language;
          this.approved = response.data.status;
          this.loading = false
          if(this.approved == 2) {
            this.stepCount = 3
            this.complete = true
          }
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
        });
    },

    updateContractData() {
      let data = {
        ...this.data.personal,
        ...this.data.financial,
        ...this.data.licence,
      };

      // Remove unused data
      if (!data.last_name_prefix || _.isEmpty(data.last_name_prefix)) {
        delete data.last_name_prefix;
      }
      if (!data.number_extention || _.isEmpty(data.number_extention)) {
        delete data.number_extention;
      }
      if (data.retirement_plan && data.retirement_plan == "0") {
        delete data.retirement_plan_type;
      }
      if (data.welfare && data.welfare == "0") {
        delete data.welfare_type;
      }
      if (data.retirement_plan && data.retirement_plan == "0") {
        delete data.retirement_plan_type;
      }
      if (data.welfare && data.welfare == "0") {
        delete data.welfare_type;
      }
      if (data.drivers_licence && data.drivers_licence == "0") {
        delete data.drivers_licence_name;
        delete data.drivers_licence_type;
        delete data.drivers_licence_limitations;
        delete data.drivers_licence_limitations_description;
        delete data.drivers_licence_number;
        delete data.drivers_licence_created_at;
        delete data.drivers_licence_expiration_date;
        delete data.drivers_licence_revoked;
        delete data.drivers_licence_revoked_reason;
        delete data.drivers_licence_outstanding_fines;
        delete data.drivers_licence_years_of_experience;
        delete data.drivers_licence_serious_accident;
        delete data.drivers_licence_criminal_record;
        delete data.drivers_licence_location;
        delete data.car_insured;
        delete data.car_insured_passengers;
        delete data.car_damaged;
        delete data.car_owner;
      }

      if (data.drivers_licence && data.drivers_licence == "0") {
        data.licence = { drivers_licence: "0" };
      }
      if (
        data.drivers_licence_limitations &&
        data.drivers_licence_limitations == "0"
      ) {
        delete data.drivers_licence_limitations_description;
      }
      if (data.drivers_licence_revoked && data.drivers_licence_revoked == "0") {
        delete data.drivers_licence_revoked_reason;
      }
      if (
        data.drivers_licence_serious_accident &&
        data.drivers_licence_serious_accident == "0"
      ) {
        delete data.drivers_licence_criminal_record;
      }
      if (data.car_owner && data.car_owner == "0") {
        delete data.car_insured;
        delete data.car_insured_passengers;
        delete data.car_damaged;
      }

      // Format dates
      data.date_of_birth = moment(data.date_of_birth).format("YYYY-MM-DD");
      data.document_expiration_date = moment(
        data.document_expiration_date
      ).format("YYYY-MM-DD");
      if (data.drivers_licence && data.drivers_licence == "1") {
        data.drivers_licence_created_at = moment(
          data.drivers_licence_created_at
        ).format("YYYY-MM-DD");
        data.drivers_licence_expiration_date = moment(
          data.drivers_licence_expiration_date
        ).format("YYYY-MM-DD");
      }

      data.language = this.language

      api
        .patch(`/contract/${this.hash}/${this.checksum}/data`, data)
        .then(() => {
          this.complete = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    switchLocale(locale) {
      const language = locale.language
      this.$i18n.locale = locale.i18n
      this.language = language

      if(this.complete) {
        return
      }

      api
        .patch(`/contract/${this.hash}/${this.checksum}/data`, { language })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: #f5f5f5;
  top: 0;
  justify-content: center;

  .section {
    margin-top: 35px;

    h1 {
      text-align: center;
    }
  }
}
.page-container {
  height: 100%;
}
.container {
  height: 100%;
}
main {
  #meta {
    p {
      font-family: Open Sans, sans-serif;
    }
  }
}
#steps_container {
  justify-content: center;
  text-align: -webkit-center;
  .steps {
    margin: 50px;
    max-width: 629px;
    .color_primary {
      color: #2495df;
    }
  }
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.error_msg {
  position: absolute;
  left: 50%;
  .notification {
    position: relative;
    left: -50%;
  }
}
.navbar {
  min-height: 35vh;
  background-image: url("../assets/images/bg.png");
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  background-size: cover;
  .section {
    padding-top: 7%;
    position: absolute;
    z-index: 100;
    h1 {
      display: inline;
      font-size: 36px;
      color: white;
      span {
        font-weight: 700;
      }
    }
  }
  .layer {
    background-color: rgba(#2495df, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.sign_modal {
  height: 100vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.no-applicant-exists {
  background-color: #f5f5f5;
}
.job-openings {
  margin-top: 25px;
}
.language-selector {
  position: absolute;
  right: 0;
  z-index: 200;
  padding: 20px;

  a {
    padding: 5px;
  }

  .flag-icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}
</style>
